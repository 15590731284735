import Footer from '../Footer/Footer';
import * as React from 'react';
import Seo from '../Seo/Seo';
import Header from '../Header/Header';
import CookieBlock from '../CookieBlock/CookieBlock';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { monthsArray } from '../../../constants/months';
import { FaqMarkup, FooterProps } from '../../Home/types';
import { toHTML } from '@portabletext/to-html';
import { isSSR } from '../../../constants/comparisons';

interface PageLayoutProps {
  markData?: any;
  children: JSX.Element;
  keywords?: string[];
  title?: string;
  description?: string;
  url?: string;
  author?: string;
  image?: string;
  footerData: FooterProps;
}

const PageLayout = ({
  markData,
  children,
  title,
  description,
  keywords,
  url,
  author,
  image,
  footerData,
}: PageLayoutProps) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<string | null>('false');
  const [isDefaultFaqBlock, setIsDefaultFaqBlock] = useState<boolean>(false);
  const [isArticlePage, setIsArticlePage] = useState<boolean>(false);
  const getNewFormatDate = (date: string | undefined) => {
    if (typeof date === 'string') {
      const milliseconds = Date.parse(date);
      const newDate = new Date(milliseconds);
      const day = newDate.getDate();
      const month = newDate.getMonth();
      const year = newDate.getFullYear();

      return `${monthsArray[month]} ${day}, ${year}`;
    }
  };

  const schemaDefaultFaqData = {
    homePage: markData,
    industries: markData,
    newIndustries: markData,
    services: markData,
    work: markData,
    article: markData?.faqMarkup,
  };

  const classNamesArray = ['.mainHome', '.industries', '.newIndustries', '.services', '.work', '.article'];
  const getDefaultFaqData = () => {
    let markupData;
    const homePage = document.querySelector('.mainHome');
    const industriesPage = document.querySelector('.industries');
    const newIndustriesPage = document.querySelector('.newIndustries');
    const servicesPage = document.querySelector('.services');
    const worksPage = document.querySelector('.work');
    const article = document.querySelector('.article');

    switch (true) {
      case !!homePage:
        markupData = schemaDefaultFaqData.homePage;
        break;
      case !!industriesPage:
        markupData = schemaDefaultFaqData.industries;
        break;
      case !!newIndustriesPage:
        markupData = schemaDefaultFaqData.newIndustries;
        break;
      case !!servicesPage:
        markupData = schemaDefaultFaqData.services;
        break;
      case !!worksPage:
        markupData = schemaDefaultFaqData.work;
        break;
      case !!article:
        markupData = schemaDefaultFaqData.article;
        break;
      default:
        markupData = null;
    }

    return {
      '@context': 'https://schema.org/',
      '@type': 'FAQPage',
      mainEntity: markupData?.map(({ _rawAnswers, question }: FaqMarkup) => {
        return {
          '@type': 'Question',
          name: question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: toHTML(_rawAnswers),
          },
        };
      }),
    };
  };

  const getArticleMicroMarkup = () => {
    const publicationDate = getNewFormatDate(markData?.publicationDate);
    const microMarkupArticleText = document.querySelector('.paragraph')?.innerHTML;
    const sliceText = microMarkupArticleText?.slice(0, microMarkupArticleText.indexOf('.') + 1);

    return {
      '@context': 'https://schema.org/',
      '@type': 'Article',
      name: markData?.title,
      datePublished: publicationDate,
      articleBody: sliceText,
    };
  };

  const socialLinks = [markData?.instagram, markData?.linkedin, markData?.medium, markData?.twitter];
  const microMarkupAuthor = {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name: markData?.fullName,
    url: markData?.slug?.current,
    image: markData?.avatar?.asset.url,
    sameAs: socialLinks,
    jobTitle: markData?.position,
    worksFor: {
      '@type': 'Organization',
      name: markData?.company?.companyName,
    },
  };

  useEffect(() => {
    if (classNamesArray.some((selector) => document.querySelector(selector))) {
      setIsDefaultFaqBlock(true);
    }

    if (typeof window !== undefined) {
      setIsArticlePage(true);
    }
  }, [isDefaultFaqBlock, isArticlePage]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      setIsVisible(localStorage.getItem('cookieState'));
      localStorage.setItem('id', `${uuidv4()}`);
    }
  }, [mounted]);

  return (
    <>
      <Header />
      <main>
        <Seo
          schemaMarkupFaq={isSSR && getDefaultFaqData()}
          schemaMarkupAuthor={microMarkupAuthor}
          schemaMarkupArticle={isArticlePage && getArticleMicroMarkup()}
          title={title}
          description={description}
          keywords={keywords}
          url={url}
          author={author}
          image={image}
        />
        {children}
        {isVisible === 'true' || (isVisible === null && <CookieBlock setIsVisible={setIsVisible} />)}
      </main>
      <Footer nodes={footerData?.nodes} />
    </>
  );
};

export default PageLayout;
