import { FooterSocialData } from './types';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import ROUTES from '../../../constants/routes';

export const footerSocialData: FooterSocialData[] = [
  {
    link: ROUTES.LINKEDIN,
    icon: SVG_ICONS_SHARED.LINKEDIN,
    alt: 'linkedin',
  },
  {
    link: ROUTES.CLUTCH,
    icon: SVG_ICONS_SHARED.CLUTCH,
    alt: 'clutch',
  },
  {
    link: ROUTES.MAIL,
    icon: SVG_ICONS_SHARED.MAIL,
    alt: 'mail',
  },
  {
    link: ROUTES.FACEBOOK,
    icon: SVG_ICONS_SHARED.FACEBOOK,
    alt: 'facebook',
  },
  {
    link: ROUTES.INSTAGRAM,
    icon: SVG_ICONS_SHARED.INSTAGRAM,
    alt: 'instagram',
  },
  {
    link: ROUTES.BEHANCE,
    icon: SVG_ICONS_SHARED.BEHANCE,
    alt: 'behance',
  },
];

export const footerCompanyDescription = 'Full cycle app development & consulting company';
