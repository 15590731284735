import React, { useEffect, useState } from 'react';
import './BannerOfSupport.scss';
import { BTN_STYLES, Button } from '../../_Shared/Button/Button';
import { navigate } from 'gatsby';
import ROUTES from '../../../constants/routes';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';

const BannerOfSupport = () => {
  const [isFullBannerVisible, setIsFullBannerVisible] = useState<boolean>(false);
  const bannerItem = ' ⸺ Stands with Ukraine';
  const fullBannerItem = 'DONATED TO SUPPORT PEOPLE IN UKRAINE';
  const fullBannerClassName = isFullBannerVisible ? 'fullBannerActive' : 'fullBanner';
  const bannerData = [];

  for (let i = 0; i < 32; i++) {
    bannerData.push(bannerItem);
  }

  return (
    <div
      className="bannerOfSupport"
      onMouseEnter={() => setIsFullBannerVisible(true)}
      onMouseLeave={() => setIsFullBannerVisible(false)}
    >
      <div className="bannerOfSupport__container">
        <div className="bannerOfSupport__inscription">
          {bannerData.map((item, index) => {
            return (
              <div className="bannerOfSupport__inner" key={index}>
                {item}
              </div>
            );
          })}
        </div>
        <div className="bannerOfSupport__blueSide" />
        <div className="bannerOfSupport__yellowSide" />
      </div>
      <div className={fullBannerClassName}>
        <div className="leftSide">
          <span>{fullBannerItem}</span>
        </div>
        <div className="rightSide">
          <Button
            className={BTN_STYLES.BTN_PRIMARY_LARGE}
            onClick={() => navigate(ROUTES.SUPPORT_UKRAINE)}
            title="SUPPORT UKRAINE"
          />
        </div>
      </div>
    </div>
  );
};

export default BannerOfSupport;
