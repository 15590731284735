import React from 'react';
import './Button.scss';

interface ButtonProps {
  title?: string;
  onClick?: () => void;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

export const BTN_STYLES = {
  BTN_PRIMARY_SMALL: 'btn btnPrimary btnSmall',
  BTN_PRIMARY_MEDIUM: 'btn btnPrimary btnMedium',
  BTN_PRIMARY_LARGE: 'btn btnPrimary btnLarge',
  BTN_PRIMARY_XLARGE: 'btn btnPrimary btnXLarge',
  BTN_OUTLINE_SMALL: 'btn btnOutline btnSmall',
  BTN_OUTLINE_MEDIUM: 'btn btnOutline btnMedium',
  BTN_SECONDARY_GRAY_SMALL: 'btn btnSecondaryGray btnSmall',
  BTN_OUTLINE_MEDIUM_BLACK: 'btn btnOutlineBlack btnMedium',
};

export const Button = ({ onClick, className, title = 'Estimate  Project', type, disabled, ...attrs }: ButtonProps) => {
  return (
    <button
      {...attrs}
      className={className || 'btn btnPrimary btnMedium'}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {title}
    </button>
  );
};
