import * as React from 'react';
import './Header.scss';
import { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import SVG_ICONS from '../../../images/home/svg';
import ModalHeader from '../ModalHeader/ModalHeader';
import { headerData } from './defaultData';
import useBurgerMenu from '../../../hooks/useBurgerMenu';
import useMenu from '../../../hooks/useMenu';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import Logo from '../Logo/Logo';
import CustomLink from '../CustomLink/CustomLink';
import ROUTES from '../../../constants/routes';
import ProgressBar from '../../Articles/ProgressBar/ProgressBar';
import {
  modalIndustriesLeftData,
  modalIndustriesRightData,
  modalServicesRightData,
  modalTechnologiesLeftData,
  modalTechnologiesRightData,
} from '../ModalHeader/defaultData';
import { modalServicesLeftData } from '../ModalHeader/defaultData';
import BannerOfSupport from '../../Home/BannerOfSupport/BannerOfSupport';

const Header = () => {
  const [headerTitle, setHeaderTitle] = useState<string>();
  const [isBiggerHeight, setIsBiggerHeight] = useState<boolean>(false);
  const [isBannerActive, setIsBannerActive] = useState<boolean>(false);
  const [isArticlePage, setIsArticlePage] = useState<boolean>(false);
  const headerClassName = (isBiggerHeight ? 'headerFixed' : '') as string;
  const { openMenuHandler, burgerMenuIsOpen } = useBurgerMenu();
  const {
    showMenuHandler,
    modalIndustries,
    setModalIndustries,
    modalServices,
    setModalServices,
    modalTechnologies,
    setModalTechnologies,
  } = useMenu();

  useEffect(() => {
    if (burgerMenuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [burgerMenuIsOpen]);

  useEffect(() => {
    setIsBiggerHeight(document.documentElement.scrollHeight >= 1500);
  }, []);

  useEffect(() => {
    if (document.querySelector('.mainHome')) {
      setIsBannerActive(true);
    }
  }, []);

  useEffect(() => {
    if (document.querySelector('.article')) {
      setIsArticlePage(true);
    }
  }, [isArticlePage]);

  return (
    <header className={headerClassName}>
      {isBannerActive && !burgerMenuIsOpen && <BannerOfSupport />}
      <div className="header">
        <div className="header__container">
          <div className="header__menu">
            <Logo color="white" />
            <ul className="header__list">
              {headerData.map(({ title, to }, index) => {
                if (title === 'Industries' || title === 'Services' || title === 'Technologies') {
                  return (
                    <div
                      key={index}
                      onMouseEnter={() => {
                        showMenuHandler(title, 'enter');
                        setHeaderTitle(title);
                      }}
                      onMouseLeave={() => showMenuHandler(title, 'leave')}
                      className="modal"
                    >
                      <li className="header__list__item">
                        <Link to={to}>{title}</Link>
                      </li>
                    </div>
                  );
                }

                return (
                  <li className="header__list__item" key={index}>
                    <Link to={to}>{title}</Link>
                  </li>
                );
              })}
            </ul>
            <img
              loading="lazy"
              className="header__burgerIcon"
              src={burgerMenuIsOpen ? SVG_ICONS.CLOSE : SVG_ICONS.BURGER}
              alt="Burger menu icon"
              onClick={() => openMenuHandler()}
            />
            <CustomLink to={ROUTES.CONTACT_US} title="Contact us" />
          </div>
          <ModalHeader
            title={headerTitle}
            setModalShow={setModalIndustries}
            modalShow={modalIndustries}
            modalLeftData={modalIndustriesLeftData}
            modalRightData={modalIndustriesRightData}
          />
          <ModalHeader
            title={headerTitle}
            setModalShow={setModalServices}
            modalShow={modalServices}
            modalLeftData={modalServicesLeftData}
            modalRightData={modalServicesRightData}
          />
          <ModalHeader
            title={headerTitle}
            setModalShow={setModalTechnologies}
            modalShow={modalTechnologies}
            modalLeftData={modalTechnologiesLeftData}
            modalRightData={modalTechnologiesRightData}
          />
        </div>
      </div>
      <BurgerMenu burgerMenuIsOpen={burgerMenuIsOpen} />
      {isArticlePage && <ProgressBar />}
    </header>
  );
};

export default Header;
