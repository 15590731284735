import { ModalData } from './types';
import SVG_ICONS_MODAL from '../../../images/modal_services/svg';
import ROUTES from '../../../constants/routes';

export const modalIndustriesLeftData: ModalData[] = [
  {
    image: SVG_ICONS_MODAL.HEART,
    path: ROUTES.TELEMEDICINE,
    text: 'Telemedicine',
  },
  {
    image: SVG_ICONS_MODAL.BARBELL,
    path: ROUTES.HEALTH_FITNESS,
    text: 'Health & Fitness',
  },
  {
    image: SVG_ICONS_MODAL.TRUCK,
    path: ROUTES.FOOD_DELIVERY,
    text: 'Delivery',
  },
  {
    image: SVG_ICONS_MODAL.USER,
    path: ROUTES.HR_SOFTWARE,
    text: 'HR App',
  },
  {
    image: SVG_ICONS_MODAL.MONITOR,
    path: ROUTES.MARKETPLACE_DEVELOPMENT,
    text: 'Marketplace',
  },
];

export const modalIndustriesRightData: ModalData[] = [
  {
    image: SVG_ICONS_MODAL.BEAUTY,
    path: ROUTES.BEAUTY_APP,
    text: 'Beauty',
  },
  {
    image: SVG_ICONS_MODAL.CAR,
    path: ROUTES.CAR_INSPECTION,
    text: 'Car Services',
  },
  {
    image: SVG_ICONS_MODAL.KNIFE,
    path: ROUTES.RESTAURANT_APP,
    text: 'Restaurant',
  },
  {
    image: SVG_ICONS_MODAL.BANK,
    path: ROUTES.FINTECH_APP,
    text: 'Fintech',
  },
  {
    image: SVG_ICONS_MODAL.SHOPPING,
    path: ROUTES.E_COMMERCE,
    text: 'E-Commerce',
  },
];

export const modalServicesLeftData: ModalData[] = [
  {
    image: SVG_ICONS_MODAL.MVP,
    path: ROUTES.MVP_DEVELOPMENT,
    text: 'MVP Development',
  },
  {
    image: SVG_ICONS_MODAL.MOBILE,
    path: ROUTES.CUSTOM_MOB_APPLICATION,
    text: 'Custom Mobile App Development',
  },
  {
    image: SVG_ICONS_MODAL.MONITOR,
    path: ROUTES.CUSTOM_WEB_APPLICATION,
    text: 'Custom Web Application Development',
  },
  {
    image: SVG_ICONS_MODAL.DESIGN,
    path: ROUTES.DESIGN_SERVICES,
    text: 'Custom Web and Mobile App Designing Services',
  },
  {
    image: SVG_ICONS_MODAL.LAUNCH,
    path: ROUTES.APP_LAUNCH,
    text: 'New App Launch and Publishing Services',
  },
];

export const modalServicesRightData: ModalData[] = [
  {
    image: SVG_ICONS_MODAL.TOOLS_BOX,
    path: ROUTES.APP_MAINTENANCE,
    text: 'Application Support and Maintenance Services',
  },
  {
    image: SVG_ICONS_MODAL.DEVELOPING,
    path: ROUTES.SOFTWARE_RESEARCH,
    text: 'Research & Development Software Services',
  },
  {
    image: SVG_ICONS_MODAL.DEVOPS,
    path: ROUTES.DEVOPS_SERVICES,
    text: 'DevOps Services',
  },
  {
    image: SVG_ICONS_MODAL.OUTSTAFF,
    path: ROUTES.OUTSTAFFING,
    text: 'IT Outstaffing Services',
  },
];

export const modalTechnologiesLeftData: ModalData[] = [
  {
    image: SVG_ICONS_MODAL.SWIFT,
    path: ROUTES.SWIFT_DEVELOPMENT,
    text: 'Swift',
  },
  {
    image: SVG_ICONS_MODAL.DJANGO,
    path: ROUTES.DJANGO_DEVELOPMENT,
    text: 'Django',
  },
  {
    image: SVG_ICONS_MODAL.FIREBASE,
    path: ROUTES.FIREBASE_DEVELOPMENT,
    text: 'Firebase',
  },
  {
    image: SVG_ICONS_MODAL.PYTHON,
    path: ROUTES.PYTHON_DEVELOPMENT,
    text: 'Python',
  },
  {
    image: SVG_ICONS_MODAL.REACT,
    path: ROUTES.REACT_NATIVE_DEVELOPMENT,
    text: 'React Native',
  },
];

export const modalTechnologiesRightData: ModalData[] = [
  {
    image: SVG_ICONS_MODAL.REACT,
    path: ROUTES.REACT_JS_DEVELOPMENT,
    text: 'React.js',
  },
  {
    image: SVG_ICONS_MODAL.DOT_NET,
    path: ROUTES.DOT_NET_DEVELOPMENT,
    text: '.NET',
  },
  {
    image: SVG_ICONS_MODAL.JS,
    path: ROUTES.JAVASCRIPT_DEVELOPMENT,
    text: 'Javascript',
  },
  {
    image: SVG_ICONS_MODAL.NODE_JS,
    path: ROUTES.NODE_JS_DEVELOPMENT,
    text: 'Node.js',
  },
];
