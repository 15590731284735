import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

interface SeoProps {
  schemaMarkupFaq?: {};
  schemaMarkupArticle?: {};
  schemaMarkupAuthor?: {};
  description?: string;
  keywords?: string[];
  title?: string;
  image?: string;
  url?: string;
  author?: string;
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`;
const Seo = ({
  description,
  keywords,
  title,
  image,
  url,
  author,
  schemaMarkupFaq,
  schemaMarkupArticle,
  schemaMarkupAuthor,
}: SeoProps) => {
  const [isFaqSection, setIsFaqSection] = useState<boolean>(false);
  const [isArticlePage, setIsArticlePage] = useState<boolean>(false);
  const [isAuthorPage, setIsAuthorPage] = useState<boolean>(false);

  useEffect(() => {
    if (document.querySelector('.article')) {
      setIsArticlePage(true);
    }

    if (document.querySelector('.author')) {
      setIsAuthorPage(true);
    }

    if (document.querySelector('.faq')) {
      setIsFaqSection(true);
    }
  }, [isFaqSection, isArticlePage]);

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata?.description;
        const metaTitle = title || data.site.siteMetadata?.title;
        const metaAuthor = author || data.siteMetadata?.author;
        const metaUrl = url || data.siteMetadata?.url;
        const metaImage = image || data.site.siteMetadata?.image;
        const metaKeywords = keywords;

        return (
          <Helmet
            defer={true}
            title={title}
            meta={[
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
              },
              {
                name: 'facebook-domain-verification',
                content: '9xq8newmwc0q9xrn0ylg1jyrm0lbxg',
              },
              {
                name: 'robots',
                content: 'index, follow',
              },
              {
                name: 'description',
                content: metaDescription,
              },
              {
                name: 'og:title',
                content: metaTitle,
              },
              {
                name: 'og:Description',
                content: metaDescription,
              },
              {
                name: 'og:type',
                content: 'website',
              },
              {
                name: 'og:image',
                content: metaImage,
              },
              {
                name: 'og:url',
                content: metaUrl,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: 'twitter:creator',
                content: metaAuthor,
              },
              {
                name: 'twitter:title',
                content: metaTitle,
              },
              {
                name: 'twitter:Description',
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0 ? { name: 'keywords', content: metaKeywords.join(', ') } : []
            )}
          >
            {isFaqSection && (
              <script defer type="application/ld+json">
                {JSON.stringify(schemaMarkupFaq)}
              </script>
            )}
            ,
            {isArticlePage && (
              <script defer type="application/ld+json">
                {JSON.stringify(schemaMarkupArticle)}
              </script>
            )}
            ,
            {isAuthorPage && (
              <script defer type="application/ld+json">
                {JSON.stringify(schemaMarkupAuthor)}
              </script>
            )}
            ,
          </Helmet>
        );
      }}
    />
  );
};

export default Seo;
