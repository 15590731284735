import { HeaderData } from './types';
import ROUTES from '../../../constants/routes';

export const burgerMenuData: HeaderData[] = [
  {
    title: 'Industries',
    to: '',
  },
  {
    title: 'Services',
    to: '',
  },
  {
    title: 'Works',
    to: ROUTES.WORKS,
  },
  {
    title: 'Technologies',
    to: '',
  },
  {
    title: 'Company',
    to: ROUTES.ABOUT_US,
  },
  {
    title: 'Blog',
    to: ROUTES.BLOG,
  },
];

export const headerData: HeaderData[] = [
  {
    title: 'Industries',
    to: '',
  },
  {
    title: 'Services',
    to: '',
  },
  {
    title: 'Works',
    to: ROUTES.WORKS,
  },
  {
    title: 'Technologies',
    to: '',
  },
  {
    title: 'Company',
    to: ROUTES.ABOUT_US,
  },
  {
    title: 'Blog',
    to: ROUTES.BLOG,
  },
];
