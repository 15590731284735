import React, { useState } from 'react';
import './BurgerMenu.scss';
import CustomLink from '../CustomLink/CustomLink';
import ROUTES from '../../../constants/routes';
import { burgerMenuData } from './defaultData';
import BurgerList from '../../BurgerList/BurgerList';

interface BurgerMenuProps {
  burgerMenuIsOpen: boolean;
}

const BurgerMenu = ({ burgerMenuIsOpen }: BurgerMenuProps) => {
  const [currentItem, setCurrentItem] = useState(100);
  const questionItemNumber = [...Array(burgerMenuData.length).keys()];

  return (
    <div className={burgerMenuIsOpen ? 'burgerMenu open' : 'burgerMenu'}>
      <div className="burgerMenu__container">
        {burgerMenuData.map(({ title, subtitles, titlePath }, index) => {
          const answerIsActive = questionItemNumber[index] === currentItem ? `active` : 'disabled';

          return (
            <BurgerList
              key={index}
              index={index}
              subtitles={subtitles}
              title={title}
              titlePath={titlePath}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              answerIsActive={answerIsActive}
              questionItemNumber={questionItemNumber}
            />
          );
        })}
      </div>
      <CustomLink title="Contact us" to={ROUTES.CONTACT_US} className="burgerMenu__btn" />
    </div>
  );
};

export default BurgerMenu;
