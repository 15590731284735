const ROUTES = {
  //-----------PAGES-----------//
  HOME: '/',
  ABOUT_US: '/about_us/',
  WORKS: '/works/',
  CONTACT_US: '/contact_us/',
  BLOG: '/blog/',
  SUPPORT_UKRAINE: 'support_ukraine/',
  AUTHOR: '/author/',
  NOT_FOUND: '/404',
  //-----------INDUSTRIES-----------//
  TELEMEDICINE: '/telemedicine-app-development/',
  HEALTH_FITNESS: '/health-and-fitness-app-development/',
  FOOD_DELIVERY: '/food-delivery-app-development/',
  HR_SOFTWARE: '/hr-software-development/',
  MARKETPLACE_DEVELOPMENT: '/marketplace-app-development/',
  BEAUTY_APP: '/beauty-salon-app-development/',
  CAR_INSPECTION: '/car-inspection-app-development/',
  RESTAURANT_APP: '/restaurant-app-development-services/',
  FINTECH_APP: '/fintech-app-development-services/',
  E_COMMERCE: '/e-commerce-app-development-services/',
  //-----------SERVICES-----------//
  CUSTOM_WEB_APPLICATION: '/custom-web-application-development/',
  CUSTOM_MOB_APPLICATION: '/custom-mobile-app-development/',
  MVP_DEVELOPMENT: '/mvp-app-development/',
  DESIGN_SERVICES: '/custom-app-design-services/',
  APP_LAUNCH: '/app-launch-and-publishing-services/',
  APP_MAINTENANCE: '/application-maintenance-and-support-services/',
  SOFTWARE_RESEARCH: '/software-research-and-development-services/',
  DEVOPS_SERVICES: '/devops-services/',
  OUTSTAFFING: '/it-outstaffing/',
  //-----------TECHNOLOGIES-----------//
  SWIFT_DEVELOPMENT: '/swift-app-development-company/',
  DJANGO_DEVELOPMENT: '/django-development-company/',
  FIREBASE_DEVELOPMENT: '/firebase-app-development-company/',
  PYTHON_DEVELOPMENT: '/python-development-outsourcing-company/',
  REACT_JS_DEVELOPMENT: '/react-js-development-company/',
  REACT_NATIVE_DEVELOPMENT: '/custom-react-native-development-company/',
  DOT_NET_DEVELOPMENT: '/custom-dot-net-development-company/',
  JAVASCRIPT_DEVELOPMENT: '/custom-javascript-development/',
  NODE_JS_DEVELOPMENT: '/node-js-development-services/',
  //-----------SOCIAL-----------//
  LINKEDIN: 'https://www.linkedin.com/company/lunka',
  INSTAGRAM: 'https://www.instagram.com/lunka.tech',
  MAIL: 'mailto:hello@lunka.tech',
  PHONE: 'tel:+380506587505',
  CLUTCH: 'https://clutch.co/profile/lunka#summary',
  BEHANCE: 'https://www.behance.net/lunkatech',
  GOOD_FIRMS: 'https://www.goodfirms.co',
  FACEBOOK: 'https://www.facebook.com/groups/298726039126253',
  MAP: 'https://www.google.com/maps/@59.4355847,24.7540069,13.5z?hl=eu',
  HOW_TO_SUPPORT_UKRAINE:
    'https://lunka.tech/blog/how-ukrainian-developers-manage-to-continue-working-in-the-wartime-and-remain-as-effective-as-before/',
};

export default ROUTES;
