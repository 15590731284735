import React, { useEffect, useRef, useState } from 'react';
import '../_Shared/Question/Question.scss';
import { Link } from 'gatsby';
import { SubtitleData } from '../_Shared/Accordion/types';

interface QuestionProps {
  index: number;
  title: string;
  titlePath: string;
  subtitles: SubtitleData[];
  answerIsActive: string;
  currentItem: number;
  setCurrentItem: (status: number) => void;
  questionItemNumber: number[];
}

const BurgerList = ({
  index,
  subtitles,
  title,
  titlePath,
  answerIsActive,
  currentItem,
  setCurrentItem,
  questionItemNumber,
}: QuestionProps) => {
  const currentItemHandler = (title: number) => {
    setCurrentItem(currentItem === title ? 100 : title);
  };

  const ulElementRef = useRef() as React.MutableRefObject<HTMLUListElement>;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const initHeight = 0;

    if (ulElementRef.current === document.querySelector('.active.burgerBlock')) {
      ulElementRef.current.style.height = `${ulElementRef.current.scrollHeight}px`;
    } else {
      ulElementRef.current.style.height = `${initHeight}px`;
      ulElementRef.current.style.paddingLeft = '0';
    }
  });

  useEffect(() => {
    if (ulElementRef.current.className === 'active burgerBlock') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  });

  const renderSubtitles = () => {
    if (subtitles) {
      return subtitles.map(({ subtitle, className, subtitlePath }, index: number) => {
        return (
          subtitlePath && (
            <Link to={subtitlePath} className="answer" key={index}>
              {subtitle}
            </Link>
          )
        );
      });
    }
  };

  return (
    <div className="burgerMenu__links" onClick={() => currentItemHandler(questionItemNumber[index])}>
      <div className="question__wrapper">
        <a href={titlePath}>{title}</a>
      </div>
      <ul
        ref={ulElementRef}
        className={answerIsActive + ' burgerBlock'}
        style={{
          marginTop: isActive ? '12px' : '0',
        }}
      >
        {renderSubtitles()}
      </ul>
    </div>
  );
};

export default BurgerList;
