import * as React from 'react';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';
import { BTN_STYLES, Button } from '../Button/Button';
import './CookieBlock.scss';

interface CookieBlockProps {
  setIsVisible: (arg: string | null) => void;
}

const CookieBlock = ({ setIsVisible }: CookieBlockProps) => {
  function visibleHandler() {
    setIsVisible('false');
  }

  return (
    <div className="cookiesBlock__wrapper">
      <div className="cookiesBlock">
        <div className="cookiesBlock__cookiesCross">
          <img loading="lazy" className="cookies" src={SVG_ICONS_SHARED.COOKIES} alt="Cookies image" />
          <img
            className="cross"
            src={SVG_ICONS_SHARED.CLOSE_CROSS}
            alt="Cookies block close cross image"
            onClick={visibleHandler}
          />
        </div>
        <p className="cookiesBlock__description">
          Lunka website uses cookies to enhance your browsing experience. By continuing to use our site, you agree to
          our Privacy Policy and use of cookies.
        </p>
        <Button
          title="Allow Cookies"
          className={BTN_STYLES.BTN_OUTLINE_SMALL}
          onClick={() => {
            if (typeof window !== undefined) {
              localStorage.setItem('cookieState', 'true');
              visibleHandler();
            }
          }}
        />
      </div>
    </div>
  );
};

export default CookieBlock;
