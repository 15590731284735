import { useState } from 'react';

const useMenu = () => {
  const [modalIndustries, setModalIndustries] = useState(false);
  const [modalServices, setModalServices] = useState(false);
  const [modalTechnologies, setModalTechnologies] = useState(false);

  const showMenuHandler = (title: string, eventType: string) => {
    if (title === 'Industries') {
      if (eventType === 'enter') {
        setModalIndustries(true);
      } else {
        setModalIndustries(false);
      }
    }

    if (title === 'Services') {
      if (eventType === 'enter') {
        setModalServices(true);
      } else {
        setModalServices(false);
      }
    }

    if (title === 'Technologies') {
      if (eventType === 'enter') {
        setModalTechnologies(true);
      } else {
        setModalTechnologies(false);
      }
    }
  };

  return {
    showMenuHandler,
    modalIndustries,
    setModalIndustries,
    modalServices,
    setModalServices,
    modalTechnologies,
    setModalTechnologies,
  };
};

export default useMenu;
