import * as React from 'react';
import './ProgressBar.scss';
import { useEffect, useState } from 'react';

const ProgressBar = () => {
  const [scrollTop, setScrollTop] = useState<number>(0);
  const header = document.querySelector('.headerFixed') as HTMLElement;
  const progressBar = document.querySelector('.progressBar') as HTMLElement;
  let lastScrollTop = 0;

  const progressBarHandler = () => {
    const windowScroll = document.documentElement.scrollTop;
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (windowScroll / windowHeight) * 100;

    setScrollTop(scrolled);
  };

  const showHideHeader = () => {
    const topPagePosition = window.pageYOffset;

    if (lastScrollTop > topPagePosition) {
      header.classList.remove('hide');
      progressBar.classList.remove('hide');
    } else {
      header?.classList.add('hide');
      progressBar?.classList.add('hide');
    }

    lastScrollTop = topPagePosition;
  };

  useEffect(() => {
    window.addEventListener('scroll', progressBarHandler);
    window.addEventListener('scroll', showHideHeader);

    return () => {
      window.removeEventListener('scroll', progressBarHandler);
      window.removeEventListener('scroll', showHideHeader);
    };
  }, [header, progressBar]);

  return <div className="progressBar" style={{ width: `${scrollTop}%` }} />;
};

export default ProgressBar;
