import { useState } from 'react';

const useBurgerMenu = () => {
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false);

  const openMenuHandler = () => {
    setBurgerMenuIsOpen(!burgerMenuIsOpen);
  };

  return {
    openMenuHandler,
    burgerMenuIsOpen,
    setBurgerMenuIsOpen,
  };
};

export default useBurgerMenu;
