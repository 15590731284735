import React from 'react';
import { Link } from 'gatsby';
import './CustomLink.scss';

interface CustomLinkProps {
  title: string;
  to: string;
  className?: string;
  index?: number;
}

export const CUSTOM_LINK_STYLES = {
  CUSTOM_LINK_OUTLINE_SMALL: 'customLink linkOutline linkSmall',
  CUSTOM_LINK_LARGE: 'customLink linkLarge',
};

const CustomLink = ({ title, to, index, className }: CustomLinkProps) => {
  return (
    <Link key={index} className={className || CUSTOM_LINK_STYLES.CUSTOM_LINK_OUTLINE_SMALL} to={to}>
      {title}
    </Link>
  );
};

export default CustomLink;
