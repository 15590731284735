import { AccordionData } from '../Accordion/types';
import ROUTES from '../../../constants/routes';

export const burgerMenuData: AccordionData[] = [
  {
    title: `Industries`,
    subtitles: [
      {
        subtitle: `Telemedicine`,
        subtitlePath: ROUTES.TELEMEDICINE,
      },
      {
        subtitle: `Health & Fitness`,
        subtitlePath: ROUTES.HEALTH_FITNESS,
      },
      {
        subtitle: `Delivery`,
        subtitlePath: ROUTES.FOOD_DELIVERY,
      },
      {
        subtitle: `HR App`,
        subtitlePath: ROUTES.HR_SOFTWARE,
      },
      {
        subtitle: `Marketplace`,
        subtitlePath: ROUTES.MARKETPLACE_DEVELOPMENT,
      },
      {
        subtitle: `Beauty`,
        subtitlePath: ROUTES.BEAUTY_APP,
      },
      {
        subtitle: `Car Services`,
        subtitlePath: ROUTES.CAR_INSPECTION,
      },
      {
        subtitle: `Restaurant`,
        subtitlePath: ROUTES.RESTAURANT_APP,
      },
      {
        subtitle: `Fintech`,
        subtitlePath: ROUTES.FINTECH_APP,
      },
      {
        subtitle: `E-Commerce`,
        subtitlePath: ROUTES.E_COMMERCE,
      },
    ],
  },
  {
    title: `Services`,
    subtitles: [
      {
        subtitle: `MVP development`,
        subtitlePath: ROUTES.MVP_DEVELOPMENT,
      },
      {
        subtitle: `Custom Mobile App Development`,
        subtitlePath: ROUTES.CUSTOM_MOB_APPLICATION,
      },
      {
        subtitle: `Custom Web Application Development`,
        subtitlePath: ROUTES.CUSTOM_WEB_APPLICATION,
      },
      {
        subtitle: `Custom Web and Mobile App Designing Services`,
        subtitlePath: ROUTES.DESIGN_SERVICES,
      },
      {
        subtitle: `New App Launch and Publishing Services`,
        subtitlePath: ROUTES.APP_LAUNCH,
      },
      {
        subtitle: `Application Support and Maintenance Services`,
        subtitlePath: ROUTES.APP_MAINTENANCE,
      },
      {
        subtitle: `Research & Development Software Services`,
        subtitlePath: ROUTES.SOFTWARE_RESEARCH,
      },
      {
        subtitle: `DevOps Services`,
        subtitlePath: ROUTES.DEVOPS_SERVICES,
      },
      {
        subtitle: `IT Outstaffing Services`,
        subtitlePath: ROUTES.OUTSTAFFING,
      },
    ],
  },
  {
    title: `Technologies`,
    subtitles: [
      {
        subtitle: `Swift`,
        subtitlePath: ROUTES.SWIFT_DEVELOPMENT,
      },
      {
        subtitle: `Django`,
        subtitlePath: ROUTES.DJANGO_DEVELOPMENT,
      },
      {
        subtitle: `Firebase`,
        subtitlePath: ROUTES.FIREBASE_DEVELOPMENT,
      },
      {
        subtitle: `Python`,
        subtitlePath: ROUTES.PYTHON_DEVELOPMENT,
      },
      {
        subtitle: `React Native`,
        subtitlePath: ROUTES.REACT_NATIVE_DEVELOPMENT,
      },
      {
        subtitle: `React.js`,
        subtitlePath: ROUTES.REACT_JS_DEVELOPMENT,
      },
      {
        subtitle: `.NET`,
        subtitlePath: ROUTES.DOT_NET_DEVELOPMENT,
      },
      {
        subtitle: `Javascript`,
        subtitlePath: ROUTES.JAVASCRIPT_DEVELOPMENT,
      },
      {
        subtitle: `Node.js`,
        subtitlePath: ROUTES.NODE_JS_DEVELOPMENT,
      },
    ],
  },
  {
    title: `Works`,
    titlePath: ROUTES.WORKS,
  },
  {
    title: `Company`,
    titlePath: ROUTES.ABOUT_US,
  },
  {
    title: `Blog`,
    titlePath: ROUTES.BLOG,
  },
];
