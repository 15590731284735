import burger from './svg/burger.svg';
import close from './svg/close.svg';
import code from './svg/code.svg';
import clock from './svg/clock.svg';
import deviceMobile from './svg/devise_mobile.svg';
import deviceDesktop from './svg/devive_monitor.svg';
import figureOne from './svg/figure_one.svg';
import figures from './svg/figures.svg';
import logoFooter from './svg/footer_logo.svg';
import lightning from './svg/lightning.svg';
import magnifyingGlass from './svg/magnifying_glass.svg';
import minus from './svg/minus.svg';
import plus from './svg/plus.svg';
import keyboardSmall from './svg/keyboard_small.svg';
import keyboardMedium from './svg/keyboard_medium.svg';
import keyboardLarge from './svg/keyboard_large.svg';

const SVG_ICONS_HOMEPAGE = {
  BURGER: burger,
  CLOSE: close,
  CODE: code,
  CLOCK: clock,
  DEVICE_MOBILE: deviceMobile,
  DEVICE_DESKTOP: deviceDesktop,
  FIGURE_ONE: figureOne,
  FIGURES: figures,
  LOGO_FOOTER: logoFooter,
  LIGHTNING: lightning,
  MAGNIFYING_GLASS: magnifyingGlass,
  MINUS: minus,
  PLUS: plus,
  KEYBOARD_SMALL: keyboardSmall,
  KEYBOARD_LARGE: keyboardLarge,
  KEYBOARD_MEDIUM: keyboardMedium,
};

export default SVG_ICONS_HOMEPAGE;
