import * as React from 'react';
import { Link, navigate } from 'gatsby';
import './ModalHeader.scss';
import { ModalData, ModalHeaderProps } from './types';

const ModalHeader = ({ setModalShow, modalShow, modalLeftData, modalRightData, title }: ModalHeaderProps) => {
  const handleModal = { display: modalShow ? 'flex' : 'none' };

  const visibleModalClassName =
    title === 'Industries'
      ? 'headerModal modalIndustries'
      : title === 'Services'
      ? 'headerModal modalServices'
      : title === 'Technologies'
      ? 'headerModal modalTechnologies'
      : undefined;

  const renderModalContent = (contentData: ModalData[] | undefined, className: string) => {
    return (
      <div className={className}>
        {contentData &&
          contentData?.map(({ image, path, text }, index: number) => {
            return (
              <div
                onClick={() => navigate(path)}
                className={path === '' ? 'headerModal__disabled' : 'headerModal__content'}
                key={index}
              >
                <img loading="lazy" src={image} alt="category" width="24px" height="24px" />
                <div>
                  <Link to={path}>{text}</Link>
                  <li>In progress</li>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <section
      style={handleModal}
      className={visibleModalClassName}
      onMouseEnter={() => setModalShow(modalShow)}
      onMouseLeave={() => setModalShow(!modalShow)}
    >
      <div className="diamond" />
      <div className="headerModal__container">
        {renderModalContent(modalLeftData, 'headerModal__left')}
        {renderModalContent(modalRightData, 'headerModal__right')}
      </div>
    </section>
  );
};

export default ModalHeader;
