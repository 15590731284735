import facebook from './svg/facebook.svg';
import instagram from './svg/instagram.svg';
import linkedin from './svg/linkedin.svg';
import mailBlack from './svg/mail.svg';
import arrowLink from './svg/arrow_link.svg';
import clock from './svg/clock.svg';
import phone from './svg/phone.svg';
import envelope from './svg/envelope.svg';
import link from './svg/link.svg';
import twitter from './svg/twitter.svg';
import cookies from './svg/cookies.svg';
import closeCross from './svg/close_cross.svg';
import accordionArrowUp from './svg/accordion_arrow_up.svg';
import accordionArrowDown from './svg/accordion_arrow_down.svg';
import star from './svg/star.svg';
import statusSuccess from './svg/status_success.svg';
import outsourcing from './svg/outsourcing.svg';
import outstaffing from './svg/outstaffing.svg';
import process from './svg/process.svg';
import snake from './svg/snake.svg';
import behance from './svg/behance.svg';
import clutch from './svg/clutch.svg';

const SVG_ICONS_SHARED = {
  FACEBOOK: facebook,
  INSTAGRAM: instagram,
  TWITTER: twitter,
  LINKEDIN: linkedin,
  ARROW_LINK: arrowLink,
  LINK: link,
  PHONE: phone,
  MAIL: mailBlack,
  ENVELOPE: envelope,
  CLOCK: clock,
  COOKIES: cookies,
  CLOSE_CROSS: closeCross,
  ACCORDION_ARROW_UP: accordionArrowUp,
  ACCORDION_ARROW_DOWN: accordionArrowDown,
  STAR: star,
  STATUS_SUCCESS: statusSuccess,
  OUTSOURCING: outsourcing,
  OUTSTAFFING: outstaffing,
  PROCESS: process,
  SNAKE: snake,
  BEHANCE: behance,
  CLUTCH: clutch,
};

export default SVG_ICONS_SHARED;
