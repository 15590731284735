import barbell from './svg/barbell.svg';
import car from './svg/car.svg';
import forkKnife from './svg/fork_knife.svg';
import heartbeat from './svg/heartbeat.svg';
import userRectangle from './svg/user_rectangle.svg';
import truck from './svg/truck.svg';
import bank from './svg/bank.svg';
import monitor from './svg/monitor.svg';
import shoppingCart from './svg/shopping_cart.svg';
import mobile from './svg/mobile.svg';
import mvp from './svg/mvp.svg';
import firebase from './svg/firebase.svg';
import smartphone from './svg/smartphone.svg';
import puzzle from './svg/puzzle.svg';
import react from './svg/react.svg';
import python from './svg/python.svg';
import django from './svg/django.svg';
import swift from './svg/swift.svg';
import dotNet from './svg/dot_net.svg';
import beauty from './svg/beauty_app.svg';
import javascript from './svg/javascript.svg';
import nodeJs from './svg/node_js.svg';
import js from './svg/js.svg';
import design from './svg/design.svg';
import launch from './svg/product_launch.svg';
import toolsBox from './svg/tools_box.svg';
import developing from './svg/developing.svg';
import devops from './svg/devops.svg';
import outstaff from './svg/outstaff.svg';

const SVG_ICONS_MODAL = {
  BARBELL: barbell,
  CAR: car,
  KNIFE: forkKnife,
  HEART: heartbeat,
  USER: userRectangle,
  TRUCK: truck,
  BANK: bank,
  MONITOR: monitor,
  SHOPPING: shoppingCart,
  MOBILE: mobile,
  MVP: mvp,
  FIREBASE: firebase,
  SMARTPHONE: smartphone,
  PUZZLE: puzzle,
  REACT: react,
  PYTHON: python,
  DJANGO: django,
  SWIFT: swift,
  DOT_NET: dotNet,
  BEAUTY: beauty,
  JAVASCRIPT: javascript,
  NODE_JS: nodeJs,
  JS: js,
  DESIGN: design,
  LAUNCH: launch,
  TOOLS_BOX: toolsBox,
  DEVELOPING: developing,
  DEVOPS: devops,
  OUTSTAFF: outstaff,
};

export default SVG_ICONS_MODAL;
