import React, { FC } from 'react';
import './Footer.scss';
import { footerCompanyDescription, footerSocialData } from './defaultData';
import SVG_ICONS_FOOTER from '../../../images/footer/svg';
import { pdfFile, privacyPolicy } from '../../../constants/defaultData';
import { FooterProps } from '../../Home/types';

const Footer: FC<FooterProps> = ({ nodes }) => {
  return (
    <footer>
      <section className="footer">
        <div className="footer__container">
          <div className="footer__lunka">
            <div className="lunka_content">
              <img loading="lazy" className="logo" src={SVG_ICONS_FOOTER.LOGO} alt="LOGO" />
              <p>{footerCompanyDescription}</p>
            </div>
            <div className="footer__images">
              {footerSocialData.map(({ link, icon, alt }, index: number) => {
                return (
                  <a href={link} target="_blank" key={index}>
                    <img loading="lazy" src={icon} alt={alt} />
                  </a>
                );
              })}
            </div>
          </div>
          <div className="footer__content">
            {nodes?.map(({ title, footerRow }, index: number) => {
              return (
                <div className="footer__company" key={index}>
                  <h6>{title}</h6>
                  {footerRow.map(({ title, link }, idx) => {
                    return (
                      <a key={idx} href={link}>
                        {title}
                      </a>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className="footer__copyright">
          <a download="Privacy Policy" href={pdfFile} target="_blank">
            {privacyPolicy}
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
